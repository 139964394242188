<template>
	<component v-bind:is="componentName" v-if="findComponent"></component>
</template>
<script>
export default {
	name: 'RegisterCreate',
	data: () => ({
		findComponent: false,
		componentName: '',
	}),
	created() {
		this.setComponentName();
	},
	methods: {
		setComponentName() {
			const componentList = [
				{ format: 'hseq-gam1-f-7', componentName: 'registers-create-a' },
				{ format: 'hseq-gam1-f-18', componentName: 'registers-create-a' },
				{ format: 'hseq-gam1-f-19', componentName: 'registers-create-a' },
				{ format: 'hseq-gam1-f-22', componentName: 'hseq-gam1-f-22-create' },

				{ format: 'hseq-sso1-f-5', componentName: 'hseq-sso1-f-5-create' },
				{ format: 'hseq-sso1-f-6', componentName: 'hseq-sso1-f-6-create' },
				{ format: 'hseq-sso1-f-18', componentName: 'registers-create-a' },
				{ format: 'hseq-sso1-f-45', componentName: 'registers-create-b' },
				{ format: 'hseq-sso1-f-87', componentName: 'hseq-sso1-f-87-create' },
				{ format: 'hseq-sso1-f-255', componentName: 'hseq-sso1-f-255-create' },

				{ format: 'hseq-sso-f-17', componentName: 'hseq-sso-f-17-create' },
			];
			const foundComponent = componentList.find(
				(element) => element.format === this.$route.params?.inspectionId
			);
			if (foundComponent) {
				this.componentName = foundComponent.componentName;
				this.findComponent = true;
			}
		},
	},
	components: {
		RegistersCreateA: () =>
			import(
				'@/components/inspections/formats/format-a/modules/create/RegistersCreate.vue'
			),
		RegistersCreateB: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-45/modules/create/RegistersCreate.vue'
			),
		HseqSso1F255Create: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-255/modules/create/RegistersCreate.vue'
			),
		HseqSsoF17Create: () =>
			import(
				'@/components/inspections/formats/hseq-sso-f-17/modules/create/RegistersCreate.vue'
			),
		HseqSso1F5Create: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-5/modules/create/RegistersCreate.vue'
			),
		HseqSso1F6Create: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-6/modules/create/RegistersCreate.vue'
			),
		HseqGam1F22Create: () =>
			import(
				'@/components/inspections/formats/hseq-gam1-f-22/modules/create/RegistersCreate.vue'
			),

		HseqSso1F87Create: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-87/modules/create/RegistersCreate.vue'
			),
	},
};
</script>
